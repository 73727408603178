import React from 'react';
import { graphql } from 'gatsby';
import { Box, Heading } from 'grommet';

import Layout from '../components/layout/layout';
import Breadcrumbs from '../components/breadcrumbs/breadcrumbs';
import Hero from '../components/hero/hero';
import PortableText from '../components/portable-text/portable-text';
import Lede from '../components/lede/lede';
import CenterColumn from '../components/center-column/center-column';
import Marker from '../components/marker/marker';
import PlacesList from '../components/places-list/places-list';
import Icon from '../components/icon';

import { slugToUrl } from '../lib/helpers';

export const query = graphql`
  query ValheimPlaceTemplateQuery($id: String!, $worldId: String!) {
    place: sanityValheimPlace(id: { eq: $id }) {
      id
      title
      shortDescription
      world {
        title
        slug {
          current
        }
      }
      _rawBody(resolveReferences: { maxDepth: 5 })
      mainImage {
        ...Image
      }
      slug {
        current
      }
    }
    otherPlaces: allSanityValheimPlace(
      filter: { world: { id: { eq: $worldId } }, id: { ne: $id } }
    ) {
      edges {
        node {
          title
          id
          slug {
            current
          }
          mainImage {
            ...Image
          }
        }
      }
    }
  }
`;

const ValheimPlacePage = ({ data, pageContext }) => {
  const place = data?.place;
  return (
    <Layout>
      <Breadcrumbs
        items={[
          {
            title: 'Home',
            href: '/'
          },
          {
            title: `Valheim World: ${place.world.title}`,
            href: slugToUrl(place.world.slug.current, 'valheimWorld')
          }
        ]}
        section={pageContext.section}
      />
      <Hero {...place}>
        <Marker text="Valheim Place" />
      </Hero>

      <CenterColumn>
        <Lede text={place.shortDescription} />

        {place._rawBody && <PortableText blocks={place._rawBody} />}
        <Box alignSelf="center" margin={{ vertical: 'xlarge' }}>
          <Icon symbol={'minecraft'} />
        </Box>
        <Box margin={{ top: 'xlarge' }}>
          <Heading>Other places</Heading>
          {data.otherPlaces && (
            <PlacesList
              places={data.otherPlaces.edges.map((edge) => edge.node)}
              urlType="valheimPlace"
            />
          )}
        </Box>
      </CenterColumn>
    </Layout>
  );
};

export default ValheimPlacePage;
